import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import MainLayout from "../layout/main-layout";
import Top from "../layout/Top";
import Header from "../layout/Header";

class Congratulations extends Component {
    render() {
        return (
            <MainLayout>
                {/* <Header></Header> */}
                <div className="header_banner"> 
                <h1>Cord Blood Banking and Storage Plans
                </h1>
                </div>
                <div className="container">    	
                <div className="row">
                <div className="col-md-10" style={{marginLeft:'10%'}}>
                <div className="Congratulations mt-4 mb-4">
                    <h1 className="text-center"> 
                    <i className="fas fa-check-circle" /> <br />
                    <strong>Congratulations</strong> on completing your online enrollment and taking the 
                    next step to securing your baby’s stem cells for the future!
                    </h1>
                    <hr className="mb-4" />
                    <h2 className="mb-4">Next Steps</h2>
                    <div className="c_steps">
                    <span>1</span>
                    <div className="c_step_des">
                        <p className="c_des_bold">Please check your email order confirmation for the next steps which includes your unique identifier number.</p> <p style={{color:'#78BB2B',fontSize:'17px'}}>(check spam if you don’t see it in your inbox)</p>
                        <p>**If you have any questions, please either email or call us at <a href="mailto:info@vitalcells.com">info@vitalcells.com</a> OR (844) 443-2355.</p>
                    </div>
                    </div>
                    <div className="c_steps">
                    <span>2</span>
                    <div className="c_step_des">
                        <p className="c_des_bold">Notify your medical professional that you are using VitalCells for newborn stem cell banking.</p>
                    </div>
                    </div>
                    
                    <div className="c_steps">
                    <span>3</span>
                    <div className="c_step_des">
                        <p className="c_des_bold">Receive your collection kit </p>
                        <p>Your kit will come with everything needed for your medical provider to perform a successful collection of cord blood & cord tissue and a maternal blood draw on the day of delivery.</p>
                        <ul>
                            <li>You can open the collection kit to ensure everything is there but DO NOT OPEN OR BREAK ANY SEALS AS THESE STERILE MATERIALS SHOULD ONLY BE HANDLED BY MEDICAL PROFESSIONALS. </li>
                            <li>The maternal blood draw will be done at the time of delivery and will be packaged with the collection kit. If not, a waiver must be completed prior to processing.</li>
                            <li>If you do not receive your kit within 5-7 days, please contact VitalCells directly. </li>
                        </ul>

                      
                        
                        
                    </div>
                    </div>
                    <div className="c_steps">
                    <span>4</span>
                    <div className="c_step_des">
                        <p className="c_des_bold">Call the medical courier to pick up the collection kit on delivery day.</p>
                        <ul>
                            <li>
                            Please use the telephone number provided in your collection kit to contact the pre-paid 24-hour medical courier service. The courier will retrieve and ship the collection kit, with <b>your baby’s cord blood, cord tissue and your maternal blood draw</b>, to the ACT facility for processing. No need to worry about payment as this courier service is included in the collection process. 
                            </li>
                            <li>Further details on the healthcare provider collection instructions, parents’ guide and kit instructions, shipping details and other information are provided inside the collection kit. 
                            </li>
                        </ul>
                    </div>
                    </div>
                    <div className="c_steps">
                    <span>5</span>
                    <div className="c_step_des">
                        <p className="c_des_bold">Leave it to VitalCells.  </p>
                        <ul>
                            <li>
                            Upon receiving the cord blood and cord tissues, VitalCells will send you updates, a banking certificate and pictures of your cells along the way. 
                            </li>
                            <li>
                            Your child's stem cells will then graduate to our adult bank, American Cell Technology. You will then be provided portal access to manage your child's stem cell storage. 
                            </li>
                        </ul>
                    </div>
                    </div>
                </div>
                </div>
                </div>
                </div>
            </MainLayout>
        );
    }
}

Congratulations.propTypes = {

};

export default Congratulations;