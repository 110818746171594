import React, { Component , useRef}  from "react";
// import  { useRef   }  from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import validator from 'validator';
import MainLayout from "../layout/main-layout";
import Top from "../layout/Top";
import Header from "../layout/Header";
import { connectSalesforce,addPayment,checkPromoCode, sendMail } from '../../actions/payActions'
// import DatePicker from 'react-date-picker'
import Moment from 'moment';
import SignatureCanvas from 'react-signature-canvas'
import DatePicker from "react-datepicker";
import valid from "card-validator";
import "react-datepicker/dist/react-datepicker.css";
import { exist } from "joi";



 


class Pay extends Component {

    constructor(){
        super();
        this.state={
            loading:false, 
            transactionId:'',         
            RecipientName:'',
            RecipientLastName:'',
            RecipientAddress:'',
            RecipientCity:'',
            RecipientState:'',
            RecipientZip:'',
            startDate : '',
            setStartDate: new Date(),
            dateOfBirth:'',
            babies:1,
            price1:4500,
            price2:0,
            step:0,
            error:'',
            errors:{},
            scroll:{},
            page_name:'plan',
            // page_name:'congrats',
            plan_id:0,
            storage_id:0,
            save1:0,
            save2:0,
            base_amount:250,
            pay:'',  
            promo_disable:false,
            trimmedDataURL:null, 
            PromoCode_emsg:"",
            PromoCode_smsg:"",
            basic_plan:"",   
            storage_plan:"", 
            emailDelivered:true,     
            formData:{'bankingPlanName':'', 'storagePlanName':'','initialProcessingFee':0,'annualBankingFee':0,'babies':1,'cell_count':0,'bankingPlanDiscount':0,'storagePlanDiscount':0, 'base_amount':250,'discount':0,'DepositCCProcessingFee':0,'InitialCCProcessingFee':0,'AnnualBankingCCProcessingFee':0},
            basic_plan_4: [
                {
                    name:'Basic',
                    price:4500,
                    cell:0,
                    Bio_insurance_cell:500,
                    saving:0,
                    discount:0 
                },
                {
                    name:'Bio-Insurance 100',
                    price:8750,
                    cell:100000000,
                    Bio_insurance_cell:500,
                    saving:750,
                    discount:15 
                },
                {
                    name:'Bio-Insurance 250',
                    price:14500,
                    cell:250000000,
                    Bio_insurance_cell:500,
                    saving:2500,
                    discount:20
                },
                {
                    name:'Bio-Insurance 500',
                    price:23250,
                    cell:500000000,
                    Bio_insurance_cell:500,
                    saving:6250,
                    discount:25 
                }
            ],
            basic_plan_1: [
                {
                    name:'Basic',
                    price:4500,
                    cell:0,
                    Bio_insurance_cell:500,
                    saving:0,
                    discount:0 
                }
            ],
            basic_plan_2: [
                
                {
                    name:'Bio-Insurance 100',
                    price:8750,
                    cell:100000000,
                    Bio_insurance_cell:500,
                    saving:750,
                    discount:15 
                },
                
               
            ],
            basic_plan_3: [
                {
                    name:'Bio-Insurance 500',
                    price:23250,
                    cell:500000000,
                    Bio_insurance_cell:500,
                    saving:6250,
                    discount:25 
                }
            ],
            
            storage_plan_4:[
                {
                    name:'Future Annual Payment',
                    label:'First Year',
                    price:0,                   
                    storage :350,
                    saving:0,
                    discount:0
                },
                {
                    name:'10 Year Storage',
                    label:'11 Years',
                    price:2975,                   
                    storage :350,
                    saving:525,
                    discount:15
                },
                {
                    name:'18 Year Storage',
                    label:'19 Years',
                    price:5040,                   
                    storage :350,
                    saving:1260,
                    discount:20
                },
                {
                    name:'25 Year Storage',
                    label:'26 Years',
                    price:6563,                   
                    storage :350,
                    saving:2188,
                    discount:25
                }
            ],
            storage_plan_1:[
                {
                    name:'Future Annual Payment',
                    label:'First Year',
                    price:0,                   
                    storage :350,
                    saving:0,
                    discount:0
                },
            ],
            storage_plan_2:[
                {
                    name:'18 Year Storage',
                    label:'19 Years',
                    price:5040,                   
                    storage :350,
                    saving:1260,
                    discount:20
                },
               
            ],
            storage_plan_3:[
                {
                    name:'25 Year Storage',
                    label:'26 Years',
                    price:6563,                   
                    storage :350,
                    saving:2188,
                    discount:25
                }
            ],
           

        }
        // this.myRef = React.createRef();
        // this.mylastName = React.createRef();
        // this.mystreet = React.createRef();
        // this.mycity = React.createRef();
        // this.myState = React.createRef();
        // this.myZip = React.createRef();
        // this.myemail = React.createRef();
        // this.mymobile = React.createRef();
        // this.mydateOfBirth = React.createRef();
        // this.myemail = React.createRef();
        
        // this.myRef = React.createRef();
        // this.myRef = React.createRef();
        // this.myRef = React.createRef();
        // this.myRef = React.createRef();
        // this.myRef = React.createRef();
        // this.myRef = React.createRef();
        // this.myRef = React.createRef();
        
    }
   
    

    componentDidMount() {  
        let dt = new Date()
        this.state.formData['bankingPlanName'] = '' 
        this.state.formData['storagePlanName'] = ''
        // this.state.formData['babies'] = 1
        // this.state.formData['DueDate'] = Moment(dt).format('MM/DD/YYYY');
        this.state.step = 0;
        // this.state.page_name = ''
        this.setState({page_name:'plan'});
        this.setState({transactionId:0});
        
        // this.props.connectSalesforce();        
      }

    componentWillReceiveProps(nextProps) {

        console.log('nextProps -------------------',nextProps.page_name);
        console.log('step   -------------------',this.step);
        

        // console.log('page name ---------',nextProps.page_name.page_name.page_name);
        
       // window.scrollTo(0, 0)
        // if (nextProps.errors) {
        //     this.setState({error : nextProps.errors });
        //     this.setState({ loading: false });  
        //     let element = document.getElementById("error_msg"); 
        //         const yOffset = -30;           
        //         const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        //         window.scrollTo({top: y, behavior: 'smooth'});    
        // }

        if(!nextProps.page_name.page_name ){

        console.log('hello');
        // exist();

        }else{

       

        

        if(nextProps.page_name.page_name != 'undefined' && nextProps.page_name.promo_code != 'undefined' && nextProps.page_name.page_name.transactionId){
            this.setState({transactionId:nextProps.page_name.page_name.transactionId});
            this.setState({page_name:nextProps.page_name.page_name.page_name});
            this.setState({ loading: false });           
            console.log('1');
            // let element = 
        }   
          
        if(nextProps.page_name.page_name != 'undefined'  && nextProps.page_name.page_name.error){
            this.setState({error:nextProps.page_name.page_name.error});
            this.setState({ loading: false });  
            let element = document.getElementById("error_msg"); 
                const yOffset = -30;           
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
            console.log('2');

        }

        if(nextProps.page_name.page_name != 'undefined'  && nextProps.page_name.page_name.error_msg){
            // errors = {}
            this.state.errors["creditCardCvv"] = nextProps.page_name.page_name.error_msg;
                let element = document.getElementById("creditCardCvv"); 
                const yOffset = -30;           
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});    
                this.setState({ loading: false });  
                element.className = "form-control error"
            console.log('3');

        }

        if(nextProps.page_name.page_name != 'undefined' && nextProps.page_name.page_name.error_date){
                this.state.errors["creditCardExpirationMonth"] = nextProps.page_name.page_name.error_date;
                this.state.errors["creditCardExpirationYear"] = nextProps.page_name.page_name.error_date;
                let element = document.getElementById("creditCardExpirationMonth"); 
                const yOffset = -30;           
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});    
                this.setState({ loading: false });  
                element.className = "form-control error"
            console.log('4');

        }

        console.log('page name --------- ', this.state.page_name);
       
        
    }

    if(nextProps.page_name.promo_code  ){

        console.log('prmocode ------', nextProps.page_name.promo_code);
        console.log('prmocode  result ------', nextProps.page_name.promo_code.result.discounted_amount);
        var pr_result = JSON.parse(nextProps.page_name.promo_code.result);
        this.setState({ loading: false }); 
        console.log('prmocode  res ------', pr_result);
        if(pr_result.discounted_amount != null){
            this.state.formData['discount'] = pr_result.discounted_amount;
            this.state.formData['promo_code_id'] = pr_result.promo_code_id;
            this.setState({PromoCode_smsg:'Promo Code Applied Successfully!!'});
            this.setState({PromoCode_emsg:''});
            
            this.setState({promo_disable:true});
        }else{
            this.setState({PromoCode_smsg:''});
            this.setState({PromoCode_emsg:'Invalid Promo Code. Please try again.'});


        }

        console.log('form d-------------',this.state.formData);
        

    }

        

      
        // console.log('nextProps error ---------------', nextProps.page_name.page_name.error);
         
        // let element = document.getElementById("Congratulations");    
        
        // console.log('element', element);
        // window.scrollTo(0, 500);
        // element.scrollIntoView({behavior: 'smooth'});   
           
    }
    
    currencyFormat(num) {
        return num.toFixed().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
     }
    

  

    convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file)
          fileReader.onload = () => {
            resolve(fileReader.result);
          }
          fileReader.onerror = (error) => {
            reject(error);
          }
        })
    }
    clear = () => {
        this.sigPad.clear()
        this.setState({ trimmedDataURL: null })
      }
    
      trim = () => {
        this.setState({ trimmedDataURL: this.sigPad.getTrimmedCanvas()
          .toDataURL('image/png') })
          this.state.formData['Signature'] = this.sigPad.getTrimmedCanvas().toDataURL('image/png');
          console.log('img', this.sigPad.getTrimmedCanvas().toDataURL('image/png'));
          
      }

    handleRadio(e){
        let isChecked = e.target.checked;
        console.log('check', isChecked);
        const a = Number(e.target.value);
        let ab = this.state.basic_plan[a];
        this.state.plan_id= a;
        this.setState({plan_id:a});    
        this.state.price1 = ab.price     
        this.state.formData['bankingPlanName'] = ab.name;
        this.state.formData['cell_count'] = ab.cell/10000000;
        this.state.formData['bankingPlanDiscount'] = ab.discount;
        this.state.formData['initialProcessingFee'] = this.state.price1*this.state.babies;
        this.state.formData['InitialCCProcessingFee'] = this.state.price1*this.state.babies*0.03;

        

        this.state.save1 = ab.saving * this.state.babies;
        console.log('form',ab.name);    
    }

    handlebackRedirect(val){
        console.log('click=> ', val);
        if(val == 'contact'){
            this.step = 1;
        }
        else if(val == 'shipping'){
            this.step = 2;
        }
        else{
            this.step = 0;
        }
        this.setState({page_name:val});
    }


    handleRadio2(e){
        let isChecked = e.target.checked;
        console.log('check', isChecked);
        const a = Number(e.target.value);
        let ab = this.state.storage_plan[a];
        this.state.storage_id= a;
        this.setState({storage_id:a});       
        this.state.price2 = ab.price  
        this.state.formData['storagePlanName'] = ab.name;
        this.state.formData['storagePlanDiscount'] = ab.discount;
        this.state.formData['annualBankingFee'] = this.state.price2 * this.state.babies;
        this.state.formData['AnnualBankingCCProcessingFee'] = this.state.price2*this.state.babies*0.03;

        this.state.save2 = ab.saving * this.state.babies;
        console.log('form',this.state.storage_id);    
    }

    // componentDidUpdate(prevProps) {
    //     console.log('------ ComponentDidUpdate ------')
    //     console.log(prevProps);
    // }
    datechange(e){
        // new Date(e);
        this.state.formData['DueDate'] = Moment(e).format('MM/DD/YYYY');
        console.log('selected date =>', this.state.formData['DueDate']);
        this.setState({startDate:e})
    }

    datechange1(e){
        // new Date(e);
        this.state.formData['dateOfBirth'] = Moment(e).format('MM/DD/YYYY');
        console.log('selected date =>', this.state.formData['dateOfBirth']);
        this.setState({dateOfBirth:e})
    }

    handleFileRead = async (event) => {
        const file = event.target.files[0]
        if(file.type == "application/pdf"){
        const base64 = await this.convertBase64(file)
        console.log(file.type);
        this.state.formData['Attachment'] = base64;
        event.target.className = "form-control" 
        }
        else{
            this.state.errors["Attachment"] = "Invalid file format.";
            this.state.formData['Attachment']="";
            
        }

    }

    convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file)
          fileReader.onload = () => {
            resolve(fileReader.result);
          }
          fileReader.onerror = (error) => {
            reject(error);
          }
        })
    }

    onChange = (e) => {       
        // this.setState({ [e.target.id]: e.target.value });
       if(e.target.id=='dateOfBirth'){
            this.state.formData[e.target.id] = Moment(e.target.value).format('MM/DD/YYYY');
        }
        else if( e.target.id=='babies'){
            let amt = 250 * Number(e.target.value);
            this.state.formData['babies'] = Number(e.target.value)
            this.setState({ babies : Number(e.target.value)});
            this.state.formData['base_amount'] = amt;    

            this.state.formData['initialProcessingFee'] = this.state.price1 * Number(e.target.value)
            this.state.formData['InitialCCProcessingFee'] = this.state.price1* Number(e.target.value) *0.03;

            this.state.formData['annualBankingFee'] = this.state.price2 * Number(e.target.value)
            this.state.formData['AnnualBankingCCProcessingFee'] = this.state.price2* Number(e.target.value) *0.03;




            let a = this.state.plan_id;
            let ab = this.state.basic_plan[a];

            let a1 = this.state.storage_id;
            let ab1 = this.state.storage_plan[a1];
            this.state.save2 = ab1.saving * Number(e.target.value)
            this.state.save1 = ab.saving * Number(e.target.value)
        }else if(e.target.id == 'RecipientName'){
                this.setState({RecipientName:e.target.value}) 
                this.state.formData[e.target.id] = e.target.value; 
                e.target.className = "form-control"  
        }
        else if(e.target.id == 'RecipientLastName'){
            this.setState({RecipientLastName:e.target.value});
            this.state.formData[e.target.id] = e.target.value;
            e.target.className = "form-control" 
        }
        else if(e.target.id == 'RecipientAddress'){
            this.setState({RecipientAddress:e.target.value});
            this.state.formData[e.target.id] = e.target.value;
            e.target.className = "form-control"
        }
        else if(e.target.id == 'RecipientCity'){
            this.setState({RecipientCity:e.target.value});
            this.state.formData[e.target.id] = e.target.value;
            e.target.className = "form-control"
        }
        else if(e.target.id == 'RecipientState'){
            this.setState({RecipientState:e.target.value});
            this.state.formData[e.target.id] = e.target.value;
            e.target.className = "form-control"
        }
        else if(e.target.id == 'RecipientZip'){
            this.setState({RecipientZip:e.target.value});
            this.state.formData[e.target.id] = e.target.value;
            e.target.className = "form-control" 
        } else if(e.target.id == 'actterms'){
          this.state.formData[e.target.id] = e.target.value;           
        }
        else{          
            this.state.formData[e.target.id] = e.target.value;    
            e.target.className = "form-control"        
        }
        // this.state.formData[e.target.id].className = "form-control" 
        console.log('form vlaue=>', this.state.formData);        
      };

    onChangeCheck = (e) => {
        let isChecked = e.target.checked;
        console.log('ischeck -->',isChecked)
        this.state.formData[e.target.id] = isChecked;
        console.log('form vlaue=>', this.state);
    };

    onChangeSameAddress = (e) =>{
        let isChecked = e.target.checked;
        console.log('ischeck -->',isChecked)
        if(isChecked){
        this.state.formData[e.target.id] = isChecked;
        // this.state.disabled =isChecked
        this.setState({ RecipientName:this.state.formData['firstName']});
        this.setState({ RecipientLastName:this.state.formData['lastName']});
        this.setState({ RecipientAddress:this.state.formData['street']});
        this.setState({ RecipientCity:this.state.formData['city']});
        this.setState({ RecipientState:this.state.formData['State']});
        this.setState({ RecipientZip:this.state.formData['Zip']});
        this.state.formData['RecipientName'] = this.state.formData['firstName']
        this.state.formData['RecipientLastName'] = this.state.formData['lastName']
        this.state.formData['RecipientAddress'] = this.state.formData['street']       
        this.state.formData['RecipientCity'] = this.state.formData['city']
        this.state.formData['RecipientState'] = this.state.formData['State']
        this.state.formData['RecipientZip'] = this.state.formData['Zip']
        }else{
        // this.state.disabled =isChecked
        this.setState({ RecipientName:''});
        this.setState({ RecipientLastName:''});
        this.setState({ RecipientAddress:''});
        this.setState({ RecipientCity:''});
        this.setState({ RecipientState:''});
        this.setState({ RecipientZip:''});
        this.state.formData['RecipientName'] = ''
        this.state.formData['RecipientLastName'] = ''
        this.state.formData['RecipientAddress'] = ''
        this.state.formData['RecipientCity'] = ''
        this.state.formData['RecipientState'] = ''
        this.state.formData['RecipientZip'] = ''
        }
        // this.setState(RecipientName: 'test');
        
    }

    handlebackClick1(e){
        window.scrollTo(0, 0);
        console.log('click=> ', e.target.name)
        this.setState({error : '' });
        if(e.target.name == 'contact'){
            this.step = 1;
        }
        else if(e.target.name == 'shipping'){
            this.step = 2;
        }
        else{
            this.step = 0;


        }
        this.setState({page_name:e.target.name});

    }

    

    handleClick1(e){
        
        // e.preventDefault();
        // e.stopPropagation();
        
        let fields = this.state.formData;
        let errors = {};
        let formIsValid = true;
        // let scroll = {};
        window.scrollTo(0, 0);
        const yOffset = -30; 

        if(e.target.name == 'contact'){
            const yOffset = -30; 
           console.log('hello plan ');
            if(!fields["NewbornPlan"] || fields["NewbornPlan"]== ""){
                formIsValid = false;
                errors["NewbornPlan"] = "Cannot be empty";
                let element = document.getElementById("NewbornPlan");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
             }
            this.setState({errors: errors});
            console.log('errros =>', errors)
           
            if(formIsValid){
                this.step = 1;

            }
           
            
        }
        else if(e.target.name == 'shipping'){

            const yOffset = -30; 
            if(!fields["HospitalState"]){
                formIsValid = false;
                errors["HospitalState"] = "Cannot be empty";
                let element = document.getElementById("HospitalState");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
             }

             if(!fields["HospitalZip"]){
                formIsValid = false;
                errors["HospitalZip"] = "Cannot be empty";
                let element = document.getElementById("HospitalZip");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
               
             }

             if(isNaN(fields["HospitalZip"])){
                formIsValid = false;
                let postalcode = valid.postalCode(fields["HospitalZip"]);
                console.log('postalcode',postalcode);
                errors["HospitalZip"] = "Invalid Zip Code";
                let element = document.getElementById("HospitalZip");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
             }       

            if(!fields["HospitalCity"]){
                formIsValid = false;
                errors["HospitalCity"] = "Cannot be empty";
                let element = document.getElementById("HospitalCity");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
            }
            if(!fields["DeliverHospitalName"]){
                formIsValid = false;
                errors["DeliverHospitalName"] = "Cannot be empty";
                let element = document.getElementById("DeliverHospitalName");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
            }

            if(!fields["HospitalAddress"]){
                formIsValid = false;
                errors["HospitalAddress"] = "Cannot be empty";
                let element = document.getElementById("HospitalAddress");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
            }

            
            if(fields["HospitalPhone"] && isNaN(fields["HospitalPhone"])){
                formIsValid = false;
                errors["HospitalPhone"] = "Invalid Phone Number";
                let element = document.getElementById("HospitalPhone");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
             }  


            if(!fields["FacilityName"]){
                formIsValid = false;
                errors["FacilityName"] = "Cannot be empty";
                let element = document.getElementById("FacilityName");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
             }

             if(!fields["FacilityPhone"]){
                formIsValid = false;
                errors["FacilityPhone"] = "Cannot be empty";
                let element = document.getElementById("FacilityPhone");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
             }

             if(isNaN(fields["FacilityPhone"])){
                formIsValid = false;
                errors["FacilityPhone"] = "Invalid Phone Number";
                let element = document.getElementById("FacilityPhone");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
             }  
             
             




            if(!fields["MedicalfirstName"]){
                formIsValid = false;
                errors["MedicalfirstName"] = "Cannot be empty";
                let element = document.getElementById("MedicalfirstName");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
             }
             if(!fields["MedicallastName"]){
                formIsValid = false;
                errors["MedicallastName"] = "Cannot be empty";
                let element = document.getElementById("MedicallastName");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
             }
             if(!fields["DeliveryInformation"]){
                formIsValid = false;
                errors["DeliveryInformation"] = "Cannot be empty";
                let element = document.getElementById("DeliveryInformation");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
             }
                     
             if(!fields["street"]){
                formIsValid = false;
                errors["street"] = "Cannot be empty";
                let element = document.getElementById("street");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
                // this.mystreet=  this.mystreet.current.scrollIntoView();  
             }  
             
             if(!fields["city"]){
                formIsValid = false;
                errors["city"] = "Cannot be empty";
                let element = document.getElementById("city");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
                // this.mycity=  this.mycity.current.scrollIntoView();  
             } 
             if(!fields["State"]){
                formIsValid = false;
                errors["State"] = "Cannot be empty";
                let element = document.getElementById("State");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
                // this.myState=  this.myState.current.scrollIntoView();  
             } 
             if(!fields["Zip"]){
                formIsValid = false;
                errors["Zip"] = "Cannot be empty";
                let element = document.getElementById("Zip");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
                // this.myZip=  this.myZip.current.scrollIntoView();  
             } 

             if(isNaN(fields["Zip"])){
                // let postalcode = valid.postalCode(fields["Zip"]);
                // console.log('postalcode 1',postalcode);
                // let postalcode = validator.isPostalCode(fields["Zip"],'US');
                // console.log('postalcode 1',postalcode);
                formIsValid = false;                
                errors["Zip"] = "Invalid Zip Code";
                let element = document.getElementById("Zip");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
                // this.myZip=  this.myZip.current.scrollIntoView();  
             }

             

            

            if(fields["email"] && !validator.isEmail(fields["email"])) {
                formIsValid = false;
                errors["email"] = "Invalid email.";
                // this.myemail=  this.myemail.current.scrollIntoView();  
            }
            

             if(!fields["email"]){
                formIsValid = false;
                errors["email"] = "Cannot be empty";
                let element = document.getElementById("email");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
                // this.myemail=  this.myemail.current.scrollIntoView();  
             }

            if(!fields["mobile"]){
                formIsValid = false;
                errors["mobile"] = "Cannot be empty";
                let element = document.getElementById("mobile");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
                // this.mymobile=  this.mymobile.current.scrollIntoView();  
             } 

             if(isNaN(fields["mobile"])){
                formIsValid = false;
                errors["mobile"] = "Invalid Phone Number";
                let element = document.getElementById("mobile");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
             }  


            if(!fields["lastName"]){
                formIsValid = false;
                errors["lastName"] = "Cannot be empty";
                let element = document.getElementById("lastName");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
                  
            }

            if(!fields["firstName"]){
                formIsValid = false;
                errors["firstName"] = "Cannot be empty";  
                // this.myRef=  this.myRef.current.scrollIntoView();  
                let element = document.getElementById("firstName");  
                console.log('element',element);            
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'}); 
                element.className = "form-control error"
                
            }   
            if(!fields["carryingMotherName"]){
                formIsValid = false;
                errors["carryingMotherName"] = "Cannot be empty";  
                // this.myRef=  this.myRef.current.scrollIntoView();  
                let element = document.getElementById("carryingMotherName");  
                console.log('element',element);            
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'}); 
                element.className = "form-control error"
                
            }      
             
            let dob = document.getElementById("dateOfBirth");
            if(!fields["dateOfBirth"] || !dob.value){
                formIsValid = false;
                errors["dateOfBirth"] = "Cannot be empty";
                let element = document.getElementById("dateOfBirth");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
                // this.mydateOfBirth=  this.mydateOfBirth.current.scrollIntoView();  
             }


             
             

            if(!fields["Relationship"]){
                formIsValid = false;
                errors["Relationship"] = "Cannot be empty";
                let element = document.getElementById("Relationship");
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
                // this.myRelationship=  this.myRelationship.current.scrollIntoView();  
            } 

            let due_date = document.getElementById("DueDate");
            if(!fields["DueDate"] || !due_date.value){
                formIsValid = false;
                errors["DueDate"] = "Cannot be empty";
                let element = document.getElementById("DueDate");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
                // this.mydateOfBirth=  this.mydateOfBirth.current.scrollIntoView();  
             }

             
             console.log('due_date',due_date.value);
             if(due_date.value){
                console.log('due_date ssssss',due_date.value);
             }

             if(formIsValid){
                this.step = 2;
                if(this.state.emailDelivered){
                const newList = this.state.formData;        
                // this.props.sendMail(newList, this.props.history);
                // this.state.emailDelivered = false;  
                }


             }
            this.setState({errors: errors});
            console.log('errros =>', errors)

        }
        else if(e.target.name == 'confirmation'){

            const yOffset = -30; 
            
            
            if(!fields["creditCardBillingEmail"]){
                formIsValid = false;
                errors["creditCardBillingEmail"] = "Cannot be empty";
                let element = document.getElementById("creditCardBillingEmail");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
             }

             if(fields["creditCardBillingEmail"] && !validator.isEmail(fields["creditCardBillingEmail"])) {
                formIsValid = false;
                errors["creditCardBillingEmail"] = "Invalid email.";
                let element = document.getElementById("creditCardBillingEmail");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
            }

           



            if(!fields["creditCardBillingZipCode"]){
                formIsValid = false;
                errors["creditCardBillingZipCode"] = "Cannot be empty";
                let element = document.getElementById("creditCardBillingZipCode");                
                    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                }
    
            if(!fields["creditCardCvv"]){
                formIsValid = false;
                errors["creditCardCvv"] = "Cannot be empty";
                let element = document.getElementById("creditCardCvv");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
            }  
            
            if(fields["creditCardCvv"] && !/^\d{3,4}$/.test(fields["creditCardCvv"])) {
                formIsValid = false;
                errors["creditCardCvv"] = "Invalid CVV code"; 
                let element = document.getElementById("creditCardCvv");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});               
            }

            // if(fields["creditCardCvv"]) {
            //    let cvv =  valid.cvv(fields["creditCardCvv"]);
            //    console.log('cvv',cvv);
            //    if(!cvv.isPotentiallyValid){
            //         formIsValid = false;
            //         errors["creditCardCvv"] = "Invalid CVV"; 
            //         let element = document.getElementById("creditCardCvv"); 
            //         element.scrollIntoView({behavior: 'smooth'});   
            //    }            
            // }

            

            
            if(!fields["creditCardExpirationMonth"]){
            formIsValid = false;
            errors["creditCardExpirationMonth"] = "Cannot be empty";
            let element = document.getElementById("creditCardExpirationMonth");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
            } 

            if(fields["creditCardExpirationMonth"]){

                let expMonth = valid.expirationMonth(fields["creditCardExpirationMonth"]);
                console.log('expmonth',expMonth.isPotentiallyValid);
                if(!expMonth.isPotentiallyValid){
                    formIsValid = false;
                    errors["creditCardExpirationMonth"] = "Invalid credit Card Expiration Month";
                    let element = document.getElementById("creditCardExpirationMonth");                
                    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                }
            }

           
            
            if(!fields["creditCardExpirationYear"]){
            formIsValid = false;
            errors["creditCardExpirationYear"] = "Cannot be empty";
            let element = document.getElementById("creditCardExpirationYear");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
            } 

            if(fields["creditCardExpirationYear"]){
                let expirationYear = valid.expirationYear(fields["creditCardExpirationYear"]);
                console.log('expirationYear',expirationYear.isPotentiallyValid);
                if(!expirationYear.isPotentiallyValid){
                    formIsValid = false;
                    errors["creditCardExpirationYear"] = "Invalid credit Card Expiration Year";
                    let element = document.getElementById("creditCardExpirationYear");                
                    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                }
            }

            if(fields["creditCardExpirationYear"] && fields["creditCardExpirationMonth"]){
                let expirationDate = valid.expirationDate(fields["creditCardExpirationMonth"]+"/"+fields["creditCardExpirationYear"]);
                console.log('expirationDate',expirationDate);
                if(!expirationDate.isPotentiallyValid){
                    formIsValid = false;
                    errors["creditCardExpirationYear"] = "Something Wrong with Expiration Year";
                    let element = document.getElementById("creditCardExpirationYear");                
                    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({top: y, behavior: 'smooth'});

                    errors["creditCardExpirationMonth"] = "Something Wrong with Expiration Month";
                    let element1 = document.getElementById("creditCardExpirationMonth");                
                    element1.scrollIntoView({behavior: 'smooth'});
                }
            }


            

            if(!fields["creditCardNumber"]){
                formIsValid = false;
                errors["creditCardNumber"] = "Cannot be empty";
                let element = document.getElementById("creditCardNumber");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
            }  
    
        

            if(fields["creditCardNumber"]) {
                let cardvalid = valid.number(fields["creditCardNumber"]);
                console.log('cardvalid',cardvalid);
                if(!cardvalid.isValid){
                    formIsValid = false;
                    errors["creditCardNumber"] = "Invalid credit card number.";
                    let element = document.getElementById("creditCardNumber");                
                    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                }
            }


            if(!fields["actterms"]){
                formIsValid = false;
                errors["actterms"] = "Please check";
                let element = document.getElementById("actterms");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
            }

            
            
            if(!fields["CC_lastname"]){
                formIsValid = false;
                errors["CC_lastname"] = "Cannot be empty";
                let element = document.getElementById("CC_lastname");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
            } 

            if(!fields["CC_firstname"]){
                formIsValid = false;
                errors["CC_firstname"] = "Cannot be empty";
                let element = document.getElementById("CC_firstname");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
            } 

            if(!fields["250nonrefundable"]){
                formIsValid = false;
                errors["250nonrefundable"] = "Please check";
                let element = document.getElementById("250nonrefundable");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
            } 

            if(!fields["RecipientCity"]){
                formIsValid = false;
                errors["RecipientCity"] = "Cannot be empty";
                let element = document.getElementById("RecipientCity");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
            }

            if(!fields["RecipientState"]){
                formIsValid = false;
                errors["RecipientState"] = "Cannot be empty";
                let element = document.getElementById("RecipientState");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
            }
            if(!fields["RecipientZip"]){
                formIsValid = false;
                errors["RecipientZip"] = "Cannot be empty";
                let element = document.getElementById("RecipientZip");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
            }

            if(!fields["RecipientAddress"]){
                formIsValid = false;
                errors["RecipientAddress"] = "Cannot be empty";
                let element = document.getElementById("RecipientAddress");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
            }

            if(!fields["RecipientName"]){
                formIsValid = false;
                errors["RecipientName"] = "Cannot be empty";
                let element = document.getElementById("RecipientName");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});

            } 

            if(!fields["RecipientLastName"]){
                formIsValid = false;
                errors["RecipientLastName"] = "Cannot be empty";
                let element = document.getElementById("RecipientLastName");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
            }
             if(formIsValid){
                this.step = 3;
             }
            this.setState({errors: errors});
            console.log('errros =>', errors)

        }
        else{
           this.state.step = 0;
        

        }
        

        if(formIsValid){
        this.setState({page_name : e.target.name});
        console.log('button click');
        }
        // this.state.page_name = 'contact';
    }

    fetchData = () => {
        this.setState({ loading: true });
    
        //Faking API call here
        setTimeout(() => {
          this.setState({ loading: false });
        }, 9000);
    };

    remove_coupon = (e) => {
        this.setState({promo_disable:false});
        this.state.formData['discount'] = 0;
        this.state.formData['promo_code_id'] = '';
        let element = document.getElementById("PromoCode"); 
        element.value = "";
        this.setState({PromoCode_emsg:''});
        this.setState({PromoCode_smsg:''});
        console.log('pro -----',this.state.promo_disable);

    }

    validate_coupon= (e) => {

        let fields = this.state.formData;
        let errors = {};
        const yOffset = -30;  
        let formIsValid = true;

        this.setState({errors: errors});

        if(!fields["PromoCode"]) {
            formIsValid = false;
            errors["PromoCode"] = "Please Enter valid promo code.";
            console.log('errors',errors);
            let element = document.getElementById("PromoCode");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
        }

        console.log('formIsValid --------',formIsValid)



        if(formIsValid){
        this.setState({ loading: true });  
        const newList = { "Name" : this.state.formData['PromoCode']};
        console.log("newlist=>", newList);
        this.props.checkPromoCode(newList, this.props.history);
        }
    }

    SetPlan = (e) => {
        console.log('val -----------------',e.target.value);
        this.state.formData[e.target.id] = e.target.value; 

        if(e.target.value == 'Basic'){
            
            
            
            this.setState({basic_plan: this.state.basic_plan_1});
            this.setState({storage_plan: this.state.storage_plan_1});
            this.state.basic_plan = this.state.basic_plan_1
            this.state.storage_plan = this.state.storage_plan_1
            

            
        }if( e.target.value == 'CellMaxx Plus'){
           
           
           
            this.setState({basic_plan: this.state.basic_plan_2});
            this.setState({storage_plan: this.state.storage_plan_2});
            this.state.basic_plan = this.state.basic_plan_2
            this.state.storage_plan = this.state.storage_plan_2


            
          

        }if( e.target.value == 'CellMaxx Premium'){
            this.state.storagePlanName = 'Future Annual Payment';
           
            this.setState({basic_plan: this.state.basic_plan_3});
            this.setState({storage_plan: this.state.storage_plan_3});
            this.state.basic_plan = this.state.basic_plan_3
            this.state.storage_plan = this.state.storage_plan_3


            
            
        }if( e.target.value == 'Choose Your Own Plan'){
            
            this.setState({basic_plan: this.state.basic_plan_4});
            this.setState({storage_plan: this.state.storage_plan_4});

            this.state.basic_plan = this.state.basic_plan_4
            this.state.storage_plan = this.state.storage_plan_4



           

            
        }
       
        console.log('this.state.basic_plan ----- ',this.state.basic_plan);
        console.log('this.state.storage_plan ----- ',this.state.storage_plan);
        
        // console.log('this.state.basic_plan ----- ',this.getState({basic_plan}));


        if(e.target.value != ""){
            const a = 0;
            let ab = this.state.basic_plan[a];

            console.log('ab-------',ab);
            this.state.plan_id= a;
            this.setState({plan_id:a});    
            this.state.price1 = ab.price     
            this.state.bankingPlanName = ab.name;

            this.state.formData['bankingPlanName'] = ab.name;
            this.state.formData['cell_count'] = ab.cell/10000000;
            this.state.formData['bankingPlanDiscount'] = ab.discount;
            this.state.formData['initialProcessingFee'] = this.state.price1*this.state.babies;
            this.state.formData['InitialCCProcessingFee'] = this.state.price1*this.state.babies*0.03;

            this.state.save1 = ab.saving * this.state.babies;


            let currStorage= this.state.storage_plan[0];
            this.state.storage_id= 0;
            this.setState({storage_id:0});       
            this.state.price2 = currStorage.price  
            this.state.formData['storagePlanName'] = currStorage.name;
            this.state.formData['storagePlanDiscount'] = currStorage.discount;
            this.state.formData['annualBankingFee'] = this.state.price2 * this.state.babies;
            this.state.formData['AnnualBankingCCProcessingFee'] = this.state.price2*this.state.babies*0.03;

            this.state.save2 = currStorage.saving * this.state.babies;
        }else{
            this.state.formData['bankingPlanName'] = "";
            this.state.formData['storagePlanName'] = "";
            this.setState({basic_plan: ""});
            this.setState({storage_plan: ""});
            this.state.basic_plan = ""
            this.state.storage_plan = ""


        }

        console.log('this.state.basic_plan ----- ',this.state.basic_plan);
        console.log('this.formData ----- ',this.state.formData);
        
    }
    

    onSubmit = (e) => {              
        e.preventDefault();    
        // window.scrollTo(0, 500);
          
        let fields = this.state.formData;
        let errors = {};
        let formIsValid = true;
        // let scroll = {};
        const yOffset = -30;  

        // if(!fields["Attachment"]){
        //     formIsValid = false;
        //     errors["Attachment"] = "Please select a pdf file";
        //     let element = document.getElementById("Attachment");                
        //     const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        //     window.scrollTo({top: y, behavior: 'smooth'});
        //     element.className = "form-control error"              
        // }

        if(!fields["Signature"]) {
            formIsValid = false;
            errors["Signature"] = "Please sign and click on check button.";
            console.log('errors',errors);
            let element = document.getElementById("Signature");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
        }
        
            if(!fields["creditCardBillingEmail"]){
                formIsValid = false;
                errors["creditCardBillingEmail"] = "Cannot be empty";
                let element = document.getElementById("creditCardBillingEmail");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
             }

             if(fields["creditCardBillingEmail"] && !validator.isEmail(fields["creditCardBillingEmail"])) {
                formIsValid = false;
                errors["creditCardBillingEmail"] = "Invalid email.";
                let element = document.getElementById("creditCardBillingEmail");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
            }


            if(!fields["creditCardBillingZipCode"]){
                formIsValid = false;
                errors["creditCardBillingZipCode"] = "Cannot be empty";
                let element = document.getElementById("creditCardBillingZipCode");                
                    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
                }
    
            if(!fields["creditCardCvv"]){
                formIsValid = false;
                errors["creditCardCvv"] = "Cannot be empty";
                let element = document.getElementById("creditCardCvv");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
            }  
            
            if(fields["creditCardCvv"] && !/^\d{3,4}$/.test(fields["creditCardCvv"])) {
                formIsValid = false;
                errors["creditCardCvv"] = "Invalid CVV code"; 
                let element = document.getElementById("creditCardCvv");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});   
                element.className = "form-control error"            
            }
            if(!fields["creditCardExpirationMonth"]){
                formIsValid = false;
                errors["creditCardExpirationMonth"] = "Cannot be empty";
                let element = document.getElementById("creditCardExpirationMonth");                
                    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({top: y, behavior: 'smooth'});
                    element.className = "form-control error"
                } 
    
                if(fields["creditCardExpirationMonth"]){
    
                    let expMonth = valid.expirationMonth(fields["creditCardExpirationMonth"]);
                    console.log('expmonth',expMonth.isPotentiallyValid);
                    if(!expMonth.isPotentiallyValid){
                        formIsValid = false;
                        errors["creditCardExpirationMonth"] = "Invalid credit Card Expiration Month";
                        let element = document.getElementById("creditCardExpirationMonth");                
                        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({top: y, behavior: 'smooth'});
                    element.className = "form-control error"
                    }
                }
    
               
                
                if(!fields["creditCardExpirationYear"]){
                formIsValid = false;
                errors["creditCardExpirationYear"] = "Cannot be empty";
                let element = document.getElementById("creditCardExpirationYear");                
                    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({top: y, behavior: 'smooth'});
                    element.className = "form-control error"
                } 
    
                if(fields["creditCardExpirationYear"]){
                    let expirationYear = valid.expirationYear(fields["creditCardExpirationYear"]);
                    console.log('expirationYear',expirationYear.isPotentiallyValid);
                    if(!expirationYear.isPotentiallyValid || fields["creditCardExpirationYear"].length != 4 ){
                        formIsValid = false;
                        errors["creditCardExpirationYear"] = "Invalid credit Card Expiration Year";
                        let element = document.getElementById("creditCardExpirationYear");                
                        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({top: y, behavior: 'smooth'});
                    element.className = "form-control error"
                    }
                }
    
                if(fields["creditCardExpirationYear"] && fields["creditCardExpirationMonth"]){
                    let expirationDate = valid.expirationDate(fields["creditCardExpirationMonth"]+"/"+fields["creditCardExpirationYear"]);
                    console.log('expirationDate',expirationDate);
                    if(!expirationDate.isPotentiallyValid ){
                        formIsValid = false;
                        errors["creditCardExpirationYear"] = "Something Wrong with Expiration Year";
                        let element = document.getElementById("creditCardExpirationYear");                
                        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                        window.scrollTo({top: y, behavior: 'smooth'});
    
                        errors["creditCardExpirationMonth"] = "Something Wrong with Expiration Month";
                        let element1 = document.getElementById("creditCardExpirationMonth");                
                        element1.scrollIntoView({behavior: 'smooth'});
                        element.className = "form-control error"
                    }
                }
    
    
                
    
                if(!fields["creditCardNumber"]){
                    formIsValid = false;
                    errors["creditCardNumber"] = "Cannot be empty";
                    let element = document.getElementById("creditCardNumber");                
                    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({top: y, behavior: 'smooth'});
                    element.className = "form-control error"
                }  
        
            
    
                if(fields["creditCardNumber"]) {
                    let cardvalid = valid.number(fields["creditCardNumber"]);
                    console.log('cardvalid',cardvalid);
                    if(!cardvalid.isValid){
                        formIsValid = false;
                        errors["creditCardNumber"] = "Invalid credit card number.";
                        let element = document.getElementById("creditCardNumber");                
                        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({top: y, behavior: 'smooth'});
                    element.className = "form-control error"
                    }
                }
    
    
                if(!fields["actterms"]){
                    formIsValid = false;
                    errors["actterms"] = "Please check";
                    let element = document.getElementById("actterms");                
                    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({top: y, behavior: 'smooth'});
                    
                }

                if(!fields["creditCardType"]){
                    formIsValid = false;
                    errors["creditCardType"] = "Cannot be empty";
                    let element = document.getElementById("creditCardType");                
                        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({top: y, behavior: 'smooth'});
                    element.className = "form-control error"
                }

                if(fields["creditCardType"]  && fields["creditCardNumber"]){
                    let cardvalid = valid.number(fields["creditCardNumber"]);
                    if(cardvalid.card.niceType){
                        if(cardvalid.card.niceType !== fields["creditCardType"]){
                            formIsValid = false;
                            errors["creditCardType"] = "Invalid card type";
                            let element = document.getElementById("creditCardType");                
                            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                            window.scrollTo({top: y, behavior: 'smooth'});
                            element.className = "form-control error"
                        }
                    }
                    
                }
                
                
                if(!fields["CC_lastname"]){
                    formIsValid = false;
                    errors["CC_lastname"] = "Cannot be empty";
                    let element = document.getElementById("CC_lastname");                
                    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({top: y, behavior: 'smooth'});
                    element.className = "form-control error"

                    // creditCardType
                } 
    
                if(!fields["CC_firstname"]){
                    formIsValid = false;
                    errors["CC_firstname"] = "Cannot be empty";
                    let element = document.getElementById("CC_firstname");                
                    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({top: y, behavior: 'smooth'});
                    element.className = "form-control error"
                } 
    
                if(!fields["250nonrefundable"]){
                    formIsValid = false;
                    errors["250nonrefundable"] = "Please check";
                    let element = document.getElementById("250nonrefundable");                
                    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({top: y, behavior: 'smooth'});
                } 
    
                if(!fields["RecipientCity"]){
                    formIsValid = false;
                    errors["RecipientCity"] = "Cannot be empty";
                    let element = document.getElementById("RecipientCity");                
                    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({top: y, behavior: 'smooth'});
                    element.className = "form-control error"
                }
    
                if(!fields["RecipientState"]){
                    formIsValid = false;
                    errors["RecipientState"] = "Cannot be empty";
                    let element = document.getElementById("RecipientState");                
                    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({top: y, behavior: 'smooth'});
                    element.className = "form-control error"
                }
                if(!fields["RecipientZip"]){
                    formIsValid = false;
                    errors["RecipientZip"] = "Cannot be empty";
                    let element = document.getElementById("RecipientZip");                
                    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({top: y, behavior: 'smooth'});
                    element.className = "form-control error"
                }
    
                if(!fields["RecipientAddress"]){
                    formIsValid = false;
                    errors["RecipientAddress"] = "Cannot be empty";
                    let element = document.getElementById("RecipientAddress");                
                    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({top: y, behavior: 'smooth'});
                    element.className = "form-control error"
                }
    
                if(!fields["RecipientName"]){
                    formIsValid = false;
                    errors["RecipientName"] = "Cannot be empty";
                    let element = document.getElementById("RecipientName");                
                    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({top: y, behavior: 'smooth'});
                    element.className = "form-control error"
    
                } 
    
                if(!fields["RecipientLastName"]){
                    formIsValid = false;
                    errors["RecipientLastName"] = "Cannot be empty";
                    let element = document.getElementById("RecipientLastName");                
                    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({top: y, behavior: 'smooth'});
                    element.className = "form-control error"
                }
        
        this.setState({errors: errors});

        console.log('formIsValid --------',formIsValid)


        if(formIsValid){
        this.setState({ loading: true });  
        
        this.state.formData['DepositCCProcessingFee'] =  this.state.formData['base_amount']* 0.03;
        this.state.formData['base_amount'] =  this.state.formData['DepositCCProcessingFee']+ this.state.formData['base_amount'];
        const newList = this.state.formData;
        console.log("newlist=>", newList);
        this.props.addPayment(newList, this.props.history);
        }
    }

    render() {
        // const {basic_plan} = this.state;
        const {errors, loading, page_name, error} = this.state;

       
        
        return (
            <MainLayout>
                 <title>Newborn Baby Form - VitalCells</title> 
                 <div className="content-wrapper">
                {/* <Header></Header> */}

                    <div id="page-content" className="page-content">
                    <div className="container">
                    <div className="">
            

                 <Top step={this.step} page={this.state.page_name}></Top> 
                          
                        <div className={(this.state.page_name == 'plan')? 'plan_selection': ''}>
                        <div id="page-main-content" className="page-main-content">
                        <div className="container">    	
                        <div className="row">
                        {/* {this.state.page_name} */}
                        <div className={(this.state.page_name == 'plan')? 'col-md-12': 'hide'}>
                        <h4 className="mb-3 mt-3" style={{lineHeight:'25px',fontStyle:'italic',fontSize:'16px'}}>
                        At VitalCells, we strive to assure long-term stem cell viability and availability. That is why we isolate, process and grow stem cells from both the umbilical cord blood and tissue at one fixed price. This allows us to prioritize that your newborn has the maximum youngest viable stem cells during their lifetime.
                        </h4>


                        {/* <h2 className="mt-3">Please select from one of the following <span className="act-blue-title">banking</span> and <span className="act-blue-title">annual storage</span> plans:</h2> */}

                        {/* <p>At VitalCells, we do more than store stem cells—we expand them, ensuring your child has the stem cells they need, when they need them, for a lifetime. </p> */}

                        <p><strong>Customize a flexible banking and storage plan that fits your needs or opt for a packaged plan that combines both</strong>. Whichever you choose, VitalCells guarantees the highest standards with our CellMaxx™ technology. Every sample is meticulously screened, viability-tested, and free of. contamination, providing your child with access to potentially billions of cells throughout their life. </p>

                        <p><strong>Basic Plan</strong> requires a 3-week notice to access your child’s stem cells, with an additional cost based on the amount of cells needed. For added flexibility, you can enhance your plan with a prepaid Bio-Insurance (stem cell) option at any time throughout your child’s life. </p>

                        <p><strong>Bio-Insurance Plans</strong> offer prepaid stem cells at a discounted rate, ensuring your child has access to their cells within 48–72 hours for emergencies or other needs. Additional plans can be purchased at any time throughout your child’s life, providing ongoing flexibility and peace of mind. </p>

                        <p>** When customizing your newborn stem cell banking plan, you can choose between convenient annual storage payments or opt for a prepaid storage plan at a discounted rate. </p>

                        <p><strong>Packaged Plans</strong> include prepaid cells and storage at a discounted rate, ensuring your child’s cells are available within 48–72 hours for emergencies or other uses. There are no additional costs for banking or storage during the specified period, providing peace of mind and convenience. </p>

                        <p>**All plans include our propriety technology <strong>CellMaxx<sup>TM</sup></strong> to ensure your child will have access to purchase more cells throughout their life, giving them their youngest, most powerful cells to use as they mature into adulthood and beyond. 
                        </p>
                        </div>
                                    
                        <div className={(this.state.page_name == 'plan')? 'col-md-7 mt-3': 'hide'}>
                        <h4>How many babies are you expecting for stem cell banking services?</h4>
                        </div>      
                        <div className={(this.state.page_name == 'plan')? 'col-md-2 mt-3': 'hide'}>
                            <select className="custom-select d-block w-100" id="babies" onChange={this.onChange} >
                                <option value="1">1 Baby</option>
                                <option value="2">2 Babies</option>
                                <option value="3">3 Babies</option>
                                <option value="4">4 Babies</option>
                                <option value="5">5 Babies</option>
                                </select>                        
                        </div>
                        
                        {(this.state.page_name != 'congrats' && this.state.page_name != 'plan' )?    
                        <div className="col-md-4 order-md-2 mb-4 mt-4">
                            <div id="act-fix" className="act-fix">
                                <ul className="act-ordr-summary list-group mb-3">
                                <li className="act-list-group-item d-flex justify-content-between lh-condensed act-plan">
                                    <div> 
                                    <small> Order Summary</small>
                                    <h6 className="my-0">{this.state.formData['bankingPlanName']} PLAN</h6>              
                                    </div>
                                    <span className="text-muted" >{this.state.formData['babies']} {(this.state.formData['babies']==1)?'Baby':'Babies'}</span>
                                </li>
                                <li className="act-list-group-item d-flex justify-content-between lh-condensed">
                                    <div>
                                    <h6 className="my-0">Processing Fees</h6>              
                                    </div>
                                    <span>${ this.currencyFormat(this.state.formData['initialProcessingFee'])}</span>
                                </li>
                                <li className="act-list-group-item d-flex justify-content-between lh-condensed">
                                    <div>
                                    <h6 className="my-0">{this.state.formData['storagePlanName']}</h6>               
                                    </div>
                                    <span>${ this.currencyFormat(this.state.formData['annualBankingFee'])}</span>
                                </li>
                                <li className="act-list-group-item d-flex justify-content-between lh-condensed">
                                    <div>
                                    <h6 className="my-0">Discount</h6>               
                                    </div>
                                    <span>${ (this.state.formData['discount'] * this.state.babies)}</span>
                                </li>
                                <li className="act-list-group-item d-flex justify-content-between act-total">
                                    <span>CC Processing Fee</span>
                                    <strong className="act_brd_blue">${ this.currencyFormat((this.state.formData['InitialCCProcessingFee'] + this.state.formData['AnnualBankingCCProcessingFee'])) }</strong>
                                </li>
                                <li className="act-list-group-item d-flex justify-content-between act-total">
                                    <span>Total Price</span>
                                    <strong className="act_brd_blue">${ this.currencyFormat((this.state.formData['initialProcessingFee'] + this.state.formData['annualBankingFee'] + this.state.formData['InitialCCProcessingFee'] + this.state.formData['AnnualBankingCCProcessingFee']) - (this.state.formData['discount'] * this.state.babies) ) }</strong>
                                </li>
                               
                                <li className="act-list-group-item d-flex justify-content-between act-bg-blue">
                                    <h6 className="my-0"><i className="fas fa-hand-holding-usd" /> Total Savings </h6>
                                    <span className="text-saving">${ this.currencyFormat(this.state.save1 + this.state.save2 + this.state.formData['InitialCCProcessingFee'] + this.state.formData['AnnualBankingCCProcessingFee'] + (this.state.formData['discount'] * this.state.babies) )}</span>
                                </li>
                                </ul>
                            </div>
                            </div>
                        :""}

        {(this.state.page_name != 'congrats')?  
                        
                    <div className={(this.state.page_name == 'plan')? "col-md-12 order-md-1 mt-4 act-form": "col-md-8 order-md-1 mt-4 act-form"}>              
                        <form className="needs-validation mt-3 mb-4" noValidate onSubmit={this.onSubmit}>
                        
                            <div className={ (this.state.page_name == 'plan')? '': 'hide' }>
                                <div className="BloodBankingPlan  mb-4">
                                <div>
                                    <h5>Please Select Plan</h5>
                                <select className="custom-select d-block w-100" id="NewbornPlan" onChange={this.SetPlan} >
                                <option value="">Please choose </option>
                                {/* <option value="Basic">Basic</option> */}
                                <option value="CellMaxx Plus">CellMaxx Plus</option>
                                <option value="CellMaxx Premium">CellMaxx Premium</option>
                                <option value="Choose Your Own Plan">Choose Your Own Plan</option>
                                
                                </select>
                                <span style={{color: "red"}}>{this.state.errors["NewbornPlan"]}</span>
                                
                                </div>
                                <br></br>

                                
                                {(this.state.basic_plan)? <h4 className="mb-3 mt-3 act-blue-title">Choose the Best Banking and Storage Plan for Your Family  <span className="bdr_light" /></h4>
                                :""}
                                <h6 className="mb-3 mt-3" style={{lineHeight:'30px'}} >
                                  

  
                                </h6>

                                
                                

                                {this.state.basic_plan &&
                                this.state.basic_plan.map((plan, i) => {
                                    return (
                                <div className="row act_plans m-0">
                                    <div className="col-md-3">
                                    <div className="act_plan_sec"> 
                                        <div className="custom-control custom-radio mb-4 act_plans_check">
                                        <input id={i} name="plan" type="radio" value={i} className="custom-control-input" onChange={e => this.handleRadio(e)} checked={ (this.state.plan_id==i) ?true:false}  />
                                        <label className="custom-control-label" htmlFor={i} />
                                        </div>
                                        <h3>{plan.name}</h3> 
                                        <h1>${this.currencyFormat(plan.price)}</h1>
                                    </div>
                                    </div>
                                    <div className="col-md-7">
                                    <div className="act_plan_detail"> 
                                        <ul>
                                        <li><p> Cryo-preserved stem cells derived from cord blood and cord tissue  </p> <span><i className="fas fa-check-circle" /></span></li>
                                        <li><p>Bio-Insurance Stem Cells included and available upon immediate request.  </p><span>  {this.currencyFormat(plan.cell)} </span>   </li>
                                    
                                        {/* <li><p></p><span>{this.currencyFormat(plan.cell)}</span></li> */}
                                        <li><p>Cost per 10mm Stem Cells After Bio-Insurance Cells</p><span>${plan.Bio_insurance_cell}</span></li>
                                        <li className="act_brd_red"><p><strong>Total Savings</strong></p><span> ${this.currencyFormat(plan.saving)}</span></li>
                                        </ul>
                                    </div>
                                    </div>
                                    <div className="col-md-2">
                                    <div className={(i==0)? 'act_discount':'act_discount'}>
                                    {(plan.name=='Basic')?'':plan.name.replace('-Insurance','')} Discount <br />
                                        <span>{plan.discount}%</span>
                                    </div>
                                    </div>
                                </div>
                                    )
                                })
                                }
                               {(this.state.basic_plan)? <h6 className="mb-3 mt-3" style={{lineHeight:'23px'}}>
                                <span className="act_required">*</span> Prepaid stem cells are stored in vials of 10 million each, providing flexibility to use them all at once or as needed. Usage is simply deducted from the total amount purchased. Additional Bio-Insurance Plans can be added at any time during the child’s life, subject to availability. 
                                </h6> : ""}
                                </div>
                                <div className="AnnualStorageFeePlan mt-4">
                                {(this.state.storage_plan)?
                                <h4 className="mb-3 mt-3 act-blue-title">Choose your Annual Storage Plan    <span className="bdr_light" /></h4>
                                :""}
                                {this.state.storage_plan &&
                                this.state.storage_plan.map((plan, i) => {
                                    return (
                                <div className="row act_plans m-0">
                                    <div className="col-md-3">
                                    <div className="act_plan_sec"> 
                                        <div className="custom-control custom-radio mb-4 act_plans_check">
                                        <input id={'a'+i} name="AnnualStorage" value={i} type="radio" className="custom-control-input" onChange={e => this.handleRadio2(e)} checked={ (this.state.storage_id==i) ?true:false} />
                                        <label className="custom-control-label" htmlFor={'a'+i} />
                                        </div>
                                        <h3>{(plan.name =='Future Annual Payment')?'Pay Annually':plan.name} </h3> 
                                        {(plan.name =='Future Annual Payment')?<span className="no_pre_plan">No Prepaid Storage Plan</span>:""}
                                        <h1>${(plan.price==0)?'350':this.currencyFormat(plan.price)}</h1>
                                    </div>
                                    </div>
                                    <div className="col-md-7">
                                    <div className="act_plan_detail mt-3"> 
                                        <ul>
                                        <li><p>First Year Annual Storage Included </p> <span><i className="fas fa-check-circle" /></span></li>
                                        <li><p>Annual Storage After {plan.label}</p><span>${plan.storage}</span></li>
                                        <li className="act_brd_red"><p><strong>Total Savings</strong></p><span>${this.currencyFormat(plan.saving)}</span></li>
                                        </ul>
                                    </div>
                                    </div>
                                    <div className="col-md-2">
                                    <div className="act_discount act_discount_storage">
                                     Discount <br />
                                        <span>{plan.discount}%</span>
                                    </div>
                                    </div>
                                </div>
                                )
                                })
                                }
                               {(this.state.storage_plan)? <h6 className="mb-3 mt-3" style={{lineHeight:'23px'}}>
                                <span className="act_required">*</span> If "Pay annually" is selected, you can extend your storage plan in the future. <i>Additional years may be added based on availability.</i>
                                </h6>:""}
                                <p className="price_based"><span>*</span> Pricing is based on per expected baby</p>
                                </div>
                                <button style={{float: 'right'}} className="btn btn-primary btn-lg" name="contact" onClick={e => this.handleClick1(e)} type="button">Save and Continue</button>
                                <div className="mb-4 mt-4" style={{float: 'left', width: '100%'}} />
                            </div>
                        
                            
                            <div  className={ (this.state.page_name == 'contact')? '': 'hide' } >
                            <div className="row">
                            <div className="col-md-6 mb-3">
                                <label htmlFor="DueDate">Baby’s Expected Due Date <span className="act_required">*</span></label>
                                {/* <input type="date" className="form-control bggray" id="DueDate" placeholder="mm/dd/yyyy" data-date="" format="MM-DD-YYYY" onChange={this.onChange}  /> */}
                                {/* <DatePicker value={new Date()}  id="DueDate1" name="DueDate1" className="form-control bggray" selected={this.state.startDate}    onChange={date => new Date(date)}                    
                                    /> */}
                                    <br></br>
                                <DatePicker id="DueDate" className="form-control bggray" minDate={new Date()}  placeholderText="mm/dd/yyyy" selected={this.state.startDate} onChange={date => this.datechange(date)} />
                                <br />
                                <span style={{color: "red"}}>{this.state.errors["DueDate"]}</span>
                                <div className="invalid-feedback">
                                Valid Baby’s Expected Due Date required.
                                </div>
                            </div>
                            </div>

                            <div className="row">

                            <div className="col-md-6 mb-3">
                                <label htmlFor="carryingMotherName">Mother or Surrogate’s Name<span className="act_required">*</span></label>
                                {/* <input type="date" className="form-control" id="dateOfBirth" placeholder="mm/dd/yyyy"   onChange={this.onChange}  /> */}
                                <br/>
                                <input   variant='outlined' type="text" className="form-control" id="carryingMotherName" placeholder="Mother or Surrogate’s Name"  required onChange={this.onChange} />
                                <span style={{color: "red"}}>{this.state.errors["carryingMotherName"]}</span>
                                <div className="invalid-feedback">
                                Valid Name required.
                                </div>
                            </div> 
                            <div className="col-md-6 mb-3">
                                <label htmlFor="dateOfBirth">Mother or Surrogate’s Date of Birth<span className="act_required">*</span></label>
                                {/* <input type="date" className="form-control" id="dateOfBirth" placeholder="mm/dd/yyyy"   onChange={this.onChange}  /> */}
                                <br/>
                                <DatePicker className="form-control bggray" id="dateOfBirth" maxDate={new Date()} placeholderText="mm/dd/yyyy" selected={this.state.dateOfBirth}  onChange={date => this.datechange1(date)} />
                                <br />
                                <span style={{color: "red"}}>{this.state.errors["dateOfBirth"]}</span>
                                <div className="invalid-feedback">
                                Valid Date of Birth required.
                                </div>
                            </div> 
                            </div>
                            
                            <h4 className="mb-3 mt-3 act-blue-title">Main Contact Information</h4>

                            <div className="row">
                            <div className="col-md-6 mb-3">
                                <label htmlFor="Relationship">Relationship to baby <span className="act_required">*</span></label>
                                <select className="custom-select d-block w-100" id="Relationship" required onChange={this.onChange} >
                                <option value="">Choose Relationship...</option>
                                <option value="Mother">Mother</option>
                                <option value="Father">Father</option>
                                <option value="Grandparent">Grandparent</option>
                                <option value="Other">Other</option>
                                
                                </select>
                                <span style={{color: "red"}}>{this.state.errors["Relationship"]}</span>
                                <div className="invalid-feedback">
                                Valid Relationship required.
                                </div>
                            </div>

                            </div>  
                            
                            <div className="row">
                            <div className="col-md-6 mb-3">
                                <label htmlFor="firstName">First name <span className="act_required">*</span></label>
                                <input   variant='outlined' type="text" className="form-control" id="firstName" placeholder="First Name"  required onChange={this.onChange} />
                                <span  style={{color: "red"}}  >{this.state.errors["firstName"]}</span>
                                <div className="invalid-feedback">
                                Valid First name is required.
                                </div>
                            </div>                    
                            <div className="col-md-6 mb-3">
                                <label htmlFor="lastName">Last name <span className="act_required">*</span></label>
                                <input type="text"  className="form-control" id="lastName" placeholder="Last Name"   onChange={this.onChange}  />
                                <span style={{color: "red"}}>{this.state.errors["lastName"]}</span>
                                <div className="invalid-feedback">
                                Valid last name is required.
                                </div>
                            </div>
                            </div>
                            <div className="row">
                            <div className="col-md-6 mb-3">
                                <label htmlFor="email">Email address <span className="act_required">*</span></label>
                                <input type="email"  className="form-control" id="email" placeholder="Email"  required onChange={this.onChange}  />
                                <span style={{color: "red"}}>{this.state.errors["email"]}</span>
                                <div className="invalid-feedback">
                                Valid Email required.
                                </div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <label htmlFor="mobile">Phone number (*no dashes)<span className="act_required">*</span></label>
                                <input type="text" className="form-control" id="mobile" placeholder="Phone Number"   onChange={this.onChange}  />
                                <span style={{color: "red"}}>{this.state.errors["mobile"]}</span>
                                <div className="invalid-feedback">
                                Valid Phone number is required.
                                </div>
                            </div>
                            </div>
                            <div className="mb-3">
                            <label htmlFor="street">Address <span className="act_required">*</span></label>
                            <textarea  className="form-control" id="street" placeholder="1234 Main St" onChange={this.onChange}></textarea>
                            {/* <input type="text" className="form-control" id="street" placeholder="1234 Main St" onChange={this.onChange}   /> */}
                            <span style={{color: "red"}}>{this.state.errors["street"]}</span>
                            <div className="invalid-feedback">
                                Please enter your shipping address.
                            </div>
                            </div>
                            <div className="mb-3">
                            {/* <input type="text" className="form-control" id="address2" placeholder="Apartment or suite" /> */}
                            </div>
                            <div className="row">
                            <div className="col-md-5 mb-3">
                                <label htmlFor="city">City <span className="act_required">*</span></label>
                                <input  type="text" className="form-control" id="city" placeholder="City" onChange={this.onChange}   />
                                <span style={{color: "red"}}>{this.state.errors["city"]}</span>
                                <div className="invalid-feedback">
                                Please select a valid city.
                                </div>
                            </div>
                            <div className="col-md-4 mb-3">
                                <label htmlFor="State">State <span className="act_required">*</span></label>
                                <input type="text"  className="form-control" id="State" placeholder="State" onChange={this.onChange}   />                                                
                                <span style={{color: "red"}}>{this.state.errors["State"]}</span>
                                <div className="invalid-feedback">
                                Please provide a valid state.
                                </div>
                            </div>
                            <div className="col-md-3 mb-3">
                                <label htmlFor="Zip">Zip <span className="act_required">*</span></label>
                                <input type="text"  className="form-control" id="Zip" placeholder="Zip" onChange={this.onChange} />
                                <span style={{color: "red"}}>{this.state.errors["Zip"]}</span>
                                <div className="invalid-feedback">
                                Zip code required.
                                </div>
                            </div>
                            </div>
                            {/* <hr className="mb-4" />
                            <h4 className="mb-3 act-blue-title">Birth Mother’s Information </h4>
                            <div className="custom-control custom-checkbox mb-4 act_nonrefundable">
                            <input type="checkbox" className="custom-control-input" id="birthmother"  onChange={this.onChangeCheck} />
                            <label className="custom-control-label act_lc" htmlFor="birthmother">Same as main contact information</label>
                            </div> */}
                            <hr className="mb-4" />
                            <h4 className="mb-0 act-blue-title">Delivery Information <span className="act_required">*</span></h4>
                            <small className="act-small-sub">Please let us know if this is an adoption or a surrogate</small>         
                            <div className="row mt-3">
                            <div className="col-md-12 mb-3">
                                <select className="custom-select d-block w-100" id="DeliveryInformation" onChange={this.onChange}  >
                                <option value="">Delivery Information...</option>
                                <option value="Does Not Apply">Does Not Apply</option>
                                <option value="Adoption">Adoption</option>
                                <option value="Surrogate">Surrogate</option>
                                </select>             
                                <span style={{color: "red"}}>{this.state.errors["DeliveryInformation"]}</span>
                                <div className="invalid-feedback">
                                Delivery Information is required
                                </div>
                            </div>
                            </div>

                          



                            <hr className="mb-4" />
                            <h4 className="mb-0 act-blue-title">Medical Professional Information </h4>
                            <small className="act-small-sub">Delivering Medical Professional Information</small>  
                            <div className="row mt-3">
                            <div className="col-md-6 mb-3">
                                <label htmlFor="FacilityName"> Facility Name <span className="act_required">*</span></label>
                                <input type="text" className="form-control" id="FacilityName" placeholder="Facility Name"  onChange={this.onChange} />
                                <span style={{color: "red"}}>{this.state.errors["FacilityName"]}</span>
                                <div className="invalid-feedback">
                                Valid Delivering Hospital is required.
                                </div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <label htmlFor="FacilityPhone">Facility Phone (*no dashes)<span className="act_required">*</span></label>
                                <input type="text" className="form-control" id="FacilityPhone" placeholder="Phone" onChange={this.onChange} />
                                <span style={{color: "red"}}>{this.state.errors["FacilityPhone"]}</span>                    
                            </div>
                            <div className="col-md-6 mb-3">
                                <label htmlFor="MedicalfirstName"> First Name <span className="act_required">*</span></label>
                                <input type="text" className="form-control" id="MedicalfirstName" placeholder="First Name" onChange={this.onChange}  />
                                <span style={{color: "red"}}>{this.state.errors["MedicalfirstName"]}</span>
                                <div className="invalid-feedback">
                                Valid first name is required.
                                </div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <label htmlFor="MedicallastName"> Last Name <span className="act_required">*</span></label>
                                <input type="text" className="form-control" id="MedicallastName" placeholder="Last Name"  onChange={this.onChange}  />
                                <span style={{color: "red"}}>{this.state.errors["MedicallastName"]}</span>
                                <div className="invalid-feedback">
                                Valid last name is required.
                                </div>
                            </div>
                            </div>
                            {/* <div className="mb-3">
                            <label htmlFor="Medicaladdress">Address</label>
                            <input type="text" className="form-control" id="Medicaladdress" placeholder="1234 Main St" onChange={this.onChange}   />
                            <div className="invalid-feedback">
                                Please enter your address.
                            </div>
                            </div>
                            <div className="mb-3">
                            <input type="text" className="form-control" id="Medicaladdress2" placeholder="Apartment or suite" onChange={this.onChange} />
                            </div> */}
                            {/* <div className="row">
                            <div className="col-md-5 mb-3">
                                <label htmlFor="MedicalCity">City</label>
                                <input type="text" className="form-control" id="MedicalCity" placeholder="City" onChange={this.onChange}   />
                                <div className="invalid-feedback">
                                Please select a valid City.
                                </div>
                            </div>
                            <div className="col-md-4 mb-3">
                                <label htmlFor="MedicalState">State</label>
                                <input type="text" className="form-control" id="MedicalState" placeholder="State" onChange={this.onChange}   />                                                
                                <div className="invalid-feedback">
                                Please provide a valid state.
                                </div>
                            </div>
                            <div className="col-md-3 mb-3">
                                <label htmlFor="MedicalZip">Zip</label>
                                <input type="text" className="form-control" id="MedicalZip" placeholder="Zip" onChange={this.onChange} />
                                <div className="invalid-feedback">
                                Zip code required.
                                </div>
                            </div>
                            </div> */}
                            <hr className="mb-4" />
                            <h4 className="mb-3 act-blue-title">Delivering Hospital </h4>
                            <div className="row">
                            <div className="col-md-6 mb-3">
                                <label htmlFor="DeliverHospitalName"> Delivery Hospital Name <span className="act_required">*</span></label>
                                <input type="text" className="form-control" id="DeliverHospitalName" placeholder="Delivery Hospital Name"  onChange={this.onChange} />
                                <span style={{color: "red"}}>{this.state.errors["DeliverHospitalName"]}</span>
                                <div className="invalid-feedback">
                                Valid Delivering Hospital is required.
                                </div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <label htmlFor="HospitalPhone">Phone (*no dashes)(optional) </label>
                                <input type="text" className="form-control" id="HospitalPhone" placeholder="Phone" onChange={this.onChange} />
                                <span style={{color: "red"}}>{this.state.errors["HospitalPhone"]}</span>
                            </div>
                            </div>
                            <div className="mb-3">
                            <label htmlFor="HospitalAddress">Address <span className="act_required">*</span></label>
                            <textarea className="form-control" id="HospitalAddress" placeholder="1234 Main St" onChange={this.onChange}></textarea>
                            {/* <input type="text" className="form-control" id="HospitalAddress" placeholder="1234 Main St" onChange={this.onChange} /> */}
                            <span style={{color: "red"}}>{this.state.errors["HospitalAddress"]}</span>
                            <div className="invalid-feedback">
                                Please enter your address.
                            </div>
                            </div>
                            {/* <div className="mb-3">
                            <input type="text" className="form-control" id="HospitalAddress2" placeholder="Apartment or suite" />
                            </div> */}
                            <div className="row">
                            <div className="col-md-5 mb-3">
                                <label htmlFor="HospitalCity">City <span className="act_required">*</span></label>
                                <input type="text" className="form-control" id="HospitalCity" placeholder="City" onChange={this.onChange}   />
                                <span style={{color: "red"}}>{this.state.errors["HospitalCity"]}</span>
                                <div className="invalid-feedback">
                                Please select a valid City.
                                </div>
                            </div>
                            <div className="col-md-4 mb-3">
                                <label htmlFor="HospitalState">State <span className="act_required">*</span></label>
                                <input type="text" className="form-control" id="HospitalState" placeholder="State" onChange={this.onChange}   />
                                <span style={{color: "red"}}>{this.state.errors["HospitalState"]}</span>
                                <div className="invalid-feedback">
                                Please provide a valid state.
                                </div>
                            </div>
                            <div className="col-md-3 mb-3">
                                <label htmlFor="HospitalZip">Zip <span className="act_required">*</span></label>
                                <input type="text" className="form-control" id="HospitalZip" placeholder="Zip" onChange={this.onChange}/>
                                <span style={{color: "red"}}>{this.state.errors["HospitalZip"]}</span>
                                <div className="invalid-feedback">
                                Zip code required.
                                </div>
                            </div>
                            </div>
                            <hr className="mb-4" />
                            <button style={{float: 'left'}} className="btn btn-primary btn-lg" name='plan' onClick={e => this.handlebackClick1(e)} type="button">Back</button>
                            <button style={{float: 'right'}} id="mybutton" className="btn btn-primary btn-lg " type="button" name="shipping" onClick={e => this.handleClick1(e)}>Save and Continue</button>
                            <br/><br/>
                        </div>
                            
                            
                            <div className={ (this.state.page_name == 'shipping')? '': 'hide' }>

                            <hr className="mb-4" />
                            <h4 className="mb-0 act-blue-title">Coupon Code (Optional) <span className="act_required"></span></h4>
                            <div className="row mt-3">
                            <div className="col-md-12 mb-3">
                            <input type="text" className="form-control" id="PromoCode" disabled={this.state.promo_disable} placeholder="Coupon Code" onChange={this.onChange}  />
                            <br></br>
                            <div>
                                <span style={{color: "green"}}>{this.state.PromoCode_smsg}</span>
                                <span style={{color: "red"}}>{this.state.PromoCode_emsg}</span>
                                <span style={{color: "red"}}>{this.state.errors["PromoCode"]}</span>
                                </div>
                            <button  id="applyCoupon" className="btn btn-primary" onClick={e => this.validate_coupon(e)}>Apply</button>
                            <button style={{marginLeft:"5px"}}  id="applyCoupon" className="btn btn-secondaryx`" onClick={e => this.remove_coupon(e)}>Remove</button>
                            
                            
                                <div className="invalid-feedback">Invalid Coupon Code </div>
                            </div>
                            </div>

                            <hr className="mb-4" />
                            <h4 className="mb-3 act-blue-title">Shipping Details</h4>
                            <div className="custom-control custom-checkbox mb-4">
                            <input type="checkbox" className="custom-control-input" id="SameAddress" onChange={this.onChangeSameAddress} />
                            <label className="custom-control-label act_lc" htmlFor="SameAddress">Shipping Address is same as Main Contact home address </label>
                            </div>
                            <div className="row">
                            <div className="col-md-6 mb-3">
                                <label htmlFor="RecipientName">Recipient’s First Name <span className="act_required">*</span></label>
                                <input type="text" className="form-control" id="RecipientName" placeholder="First Name" onChange={this.onChange} value ={this.state.RecipientName}    />
                                <span style={{color: "red"}}>{this.state.errors["RecipientName"]}</span>
                                <div className="invalid-feedback">
                                Valid first name is required.
                                </div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <label htmlFor="RecipientLastName">Recipient’s Last Name <span className="act_required">*</span></label>
                                <input type="text" className="form-control" id="RecipientLastName" placeholder="Last Name" onChange={this.onChange} value={this.state.RecipientLastName}     />
                                <span style={{color: "red"}}>{this.state.errors["RecipientLastName"]}</span>
                                <div className="invalid-feedback">
                                Valid last name is required.
                                </div>
                            </div>
                            </div>
                            <div className="mb-3">
                            <label htmlFor="RecipientAddress">Address <span className="act_required">*</span></label>
                            <textarea className="form-control" id="RecipientAddress" placeholder="1234 Main St" onChange={this.onChange}  value={this.state.RecipientAddress} />
                            {/* <input type="text" className="form-control" id="RecipientAddress" placeholder="1234 Main St" onChange={this.onChange} value={this.state.RecipientLastName}   /> */}
                            <span style={{color: "red"}}>{this.state.errors["RecipientAddress"]}</span>
                            <div className="invalid-feedback">
                                Please enter your address.
                            </div>
                            </div>
                            {/* <div className="mb-3">
                            <input type="text" className="form-control" id="address2" placeholder="Apartment or suite" />
                            </div> */}
                            <div className="row">
                            <div className="col-md-5 mb-3">
                                <label htmlFor="RecipientCity">City <span className="act_required">*</span></label>
                                <input type="text" className="form-control" id="RecipientCity" placeholder="City" onChange={this.onChange} value={this.state.RecipientCity}    />
                                <span style={{color: "red"}}>{this.state.errors["RecipientState"]}</span>
                                <div className="invalid-feedback">
                                Please select a valid city.
                                </div>
                            </div>
                            <div className="col-md-4 mb-3">
                                <label htmlFor="RecipientState">State <span className="act_required">*</span></label>
                                <input type="text" className="form-control" id="RecipientState" placeholder="State" onChange={this.onChange} value={this.state.RecipientState}      />
                                <span style={{color: "red"}}>{this.state.errors["RecipientState"]}</span>
                                <div className="invalid-feedback">
                                Please provide a valid state.
                                </div>
                            </div>
                            <div className="col-md-3 mb-3">
                                <label htmlFor="RecipientZip">Zip <span className="act_required">*</span></label>
                                <input type="text" className="form-control" id="RecipientZip" placeholder="Zip" onChange={this.onChange} value={this.state.RecipientZip}   />
                                <span style={{color: "red"}}>{this.state.errors["RecipientZip"]}</span>
                                <div className="invalid-feedback">
                                Zip code required.
                                </div>
                            </div>
                            </div>
                            <hr className="mb-4" />
                            <h4 className="mb-3 act-blue-title">Payment Details</h4>
                            <div className="custom-control custom-checkbox mb-4 act_nonrefundable">
                            <input type="checkbox" className="custom-control-input" id="250nonrefundable" onChange={this.onChangeCheck} />
                            <label className="custom-control-label act_lc" htmlFor="250nonrefundable">I understand that I will be charged a <strong>$250 non-refundable</strong> deposit and the remaining balance 
                                will be charged at or near expected date of baby delivery. </label>
                                <span style={{color: "red"}}>{this.state.errors["250nonrefundable"]}</span>
                            </div>
                            <div className="row">
                            <div className="col-md-6 mb-3">
                                <label htmlFor="CC_firstname">Card holder first name <span className="act_required">*</span></label>
                                <input type="text" className="form-control" id="CC_firstname"  onChange={this.onChange} placeholder="Card holder first name"  />                
                                <span style={{color: "red"}}>{this.state.errors["CC_firstname"]}</span>
                                <div className="invalid-feedback">
                                Name on card is required
                                </div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <label htmlFor="CC_lastname">Card holder last name  <span className="act_required">*</span></label>
                                <input type="text" className="form-control" id="CC_lastname"  onChange={this.onChange} placeholder="Card holder last name"  />                
                                <span style={{color: "red"}}>{this.state.errors["CC_lastname"]}</span>
                                <div className="invalid-feedback">
                                Name on card is required
                                </div>
                            </div>
                            <div className="col-md-12 mb-3">
                                <label htmlFor="creditCardNumber">Card Number <span className="act_required">*</span></label>
                                <input type="text" className="form-control" id="creditCardNumber" onChange={this.onChange} placeholder="Card Number"  />
                                <span style={{color: "red"}}>{this.state.errors["creditCardNumber"]}</span>
                                <div className="invalid-feedback">
                                Credit card number is required
                                </div>
                            </div>
                            </div>
                            <div className="row">
                            <div className="col-md-4 mb-3">
                                <label htmlFor="creditCardExpirationMonth">Card Expiration Date <span className="act_required">*</span></label>
                                <select className="custom-select d-block w-100" id="creditCardExpirationMonth" onChange={this.onChange}  placeholder="mm" >
                                    <option value="">Select Month</option>
                                    <option value="01">01</option>
                                    <option value="02">02</option> 
                                    <option value="03">03</option> 
                                    <option value="04">04</option> 
                                    <option value="05">05</option>    
                                    <option value="06">06</option> 
                                    <option value="07">07</option> 
                                    <option value="08">08</option> 
                                    <option value="09">09</option> 
                                    <option value="10">10</option> 
                                    <option value="11">11</option>
                                    <option value="12">12</option>  
                                </select>
                                {/* <input type="text" className="form-control" id="creditCardExpirationMonth" onChange={this.onChange} placeholder="mm" required /> */}
                                <span style={{color: "red"}}>{this.state.errors["creditCardExpirationMonth"]}</span>
                                <div className="invalid-feedback">
                                Valid Month required
                                </div>
                            </div>
                            <div className="col-md-2 mb-3">
                                <label htmlFor="creditCardExpirationYear"> &nbsp;</label>
                                {/* <select className="custom-select d-block w-100" id="creditCardExpirationYear" onChange={this.onChange}  placeholder="mm" >
                                <option value="">Select Year</option>
                                <option value="2021">2021</option>
                                <option value="2022">2022</option>
                                <option value="2023">2023</option>
                                <option value="2024">2024</option>
                                <option value="2025">2025</option>
                                <option value="2026">2026</option>
                                <option value="2027">2027</option>
                                <option value="2028">2028</option>
                                <option value="2029">2029</option>
                                <option value="2030">2030</option>
                                </select> */}
                                <input type="text" className="form-control" id="creditCardExpirationYear" onChange={this.onChange} placeholder="YYYY" required />
                                
                                <span style={{color: "red"}}>{this.state.errors["creditCardExpirationYear"]}</span><div className="invalid-feedback">
                                Valid Year required
                                </div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <label htmlFor="creditCardCvv">CVV Code <span className="act_required">*</span></label>
                                <input type="text" className="form-control" id="creditCardCvv" onChange={this.onChange} placeholder="CVV Code"  />
                                <span style={{color: "red"}}>{this.state.errors["creditCardCvv"]}</span>
                                <div className="invalid-feedback">
                                Valid CVV required
                                </div>
                            </div>
                            </div>
                            <div className="row">
                            <div className="col-md-6 mb-3">
                                <label htmlFor="creditCardType">Type of card <span className="act_required">*</span></label>                
                                <select className="custom-select d-block w-100" id="creditCardType" onChange={this.onChange} >
                                <option value="" >Select Type of card...</option>
                                <option value="Visa">Visa</option>
                                <option value="Mastercard">Master Card</option>
                                <option value="American Express">American Express</option>                       
                                <option value="Discover">Discover</option>
                                </select>
                                <span style={{color: "red"}}>{this.state.errors["creditCardType"]}</span>
                                <div className="invalid-feedback">
                                Select Type of card
                                </div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <label htmlFor="">&nbsp;</label><br />
                                <img src="image/cards.jpg" alt="" />
                            </div>
                            </div>
                            <div className="row">
                            <div className="col-md-6 mb-3">
                                <label htmlFor="creditCardBillingZipCode">Billing zip code <span className="act_required">*</span></label>
                                <input type="text" className="form-control" onChange={this.onChange} id="creditCardBillingZipCode" placeholder="Billing Zip Code"  />               
                                <span style={{color: "red"}}>{this.state.errors["creditCardBillingZipCode"]}</span>
                                <div className="invalid-feedback">
                                Billing zip code is required
                                </div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <label htmlFor="creditCardBillingEmail">Billing email address <span className="act_required">*</span></label>
                                <input type="email" className="form-control" id="creditCardBillingEmail" placeholder="Billing email address" onChange={this.onChange} />
                                <span style={{color: "red"}}>{this.state.errors["creditCardBillingEmail"]}</span>
                                <div className="invalid-feedback">
                                Billing email is required
                                </div>
                            </div>
                            </div>
                            {/* <div className="row"> 
                            <div className="col-md-6 mb-3">
                                <label>Attachment <span className="act_required">*</span></label>
                                <input   variant='outlined' type="file" className="form-control" id="Attachment" placeholder=""  onChange={e => this.handleFileRead(e)} />
                                <span  style={{color: "red"}}  >{this.state.errors["Attachment"]}</span>
                                <p>Please attach your completed and signed Newborn Client Stem Cell Storage Agreement form for review <a target="_blank" href="/ACT_Newborn_Agreement.pdf">  Form Link </a></p>
                                <div className="invalid-feedback">
                                Valid file is required.
                                </div>
                            </div >                  
                            
                            </div>  */}
                            <div className="custom-control custom-checkbox mb-4 act_nonrefundable">
                            <input type="checkbox" className="custom-control-input" id="actterms" onChange={this.onChangeCheck} />
                            <label className="custom-control-label act_lc" htmlFor="actterms">I agree to VitalCell's  <a style={{textDecoration:'underline'}} target="_blank" href="/ACT_Newborn_Agreement.pdf" >Newborn Client Stem Cell Storage Agreement.</a></label>
                            <br />
                            <span style={{color: "red"}}>{this.state.errors["actterms"]}</span>
                            </div>
                            <div className="row">
                            <div className="col-md-6 mb-3">
                                <label>Signature <span id="Signature"   className="act_required">*</span></label>
                                <SignatureCanvas  ref={(ref) => { this.sigPad = ref }} penColor='white' backgroundColor="gray"  canvasProps={{width: 500, height: 100, className: 'sigCanvas'}} />
                                <span style={{color: "red"}}> {this.state.errors["Signature"]}</span>
                                <div>
                                    <button className="btn  btn-lg" type="button"  onClick={this.clear}>
                                    Clear
                                    </button>  &nbsp;
                                    <button className="btn  btn-lg" type="button"  onClick={this.trim}>
                                    Click to Confirm Signature
                                    </button>
                                </div> 
                                
                            </div>                            
                            </div> 
                            <hr className="mb-4" />
                        
                            <div className="row act_pos_r" id="error_msg" >  { (error)? 
                            <div className="custom-control custom-checkbox mb-4 act_nonrefundable">    
                                                
                        <span  style={{color: "red"}}> <b>{error}</b></span>  
                            <br/>          
                            </div>   :''}   
                            </div>
                            <div className="row act_pos_r">
                                { (this.state.loading)?  <img style={{float:'right'}} src="/image/loader.gif" />:''}
                                <div className="col-md-12">
                                <button style={{float: 'left'}} className="btn btn-primary btn-lg" name='contact' onClick={e => this.handlebackClick1(e)} type="button">Back</button>
                                <button type="submit" style={{float: 'right'}}   className="btn btn-primary btn-lg" disabled={loading}>
                                    <span> Submit</span>
                                </button>                
                                </div>
                            </div>
                            {/* <button style={{float:'left'}} className="btn btn-primary btn-lg " name='contact' onClick={e => this.handlebackClick1(e)} type="button">Back</button>
                            <button style={{float:'right'}} className="btn btn-primary btn-lg " name="confirmation" type="button" onClick={e => this.handleClick1(e)}>Save and Continue</button> */}
                            <br/> <br/>
                        </div>
                        </form>
                        </div>
                        :
                        <div className="col-md-12">
                        <div className="Congratulations mt-4 mb-4" id="Congratulations">
                            <h1 className="text-center"> 
                            <i className="fas fa-check-circle" /> <br /> 
                            <p>Reference number is: {this.state.transactionId}</p>
                            <strong>Congratulations {this.state.formData['firstName']} </strong> on completing your online enrollment and taking the 
                            next step to securing your baby’s stem cells for the future!
                            </h1>
                            <hr className="mb-4" />
                            <h2 className="mb-4">Next Steps</h2>
                            <div className="c_steps">
                            <span>1</span>
                            <div className="c_step_des">
                            <p className="c_des_bold">Please check your email order confirmation for the next steps which includes your unique identifier number.</p> <p style={{color:'#78BB2B',fontSize:'17px'}}>(check spam if you don’t see it in your inbox)</p>
                            <p>**If you have any questions, please either email or call us at <a href="mailto:info@vitalcells.com">info@vitalcells.com</a> OR (844) 443-2355.</p>
                            </div>
                            </div>
                            <div className="c_steps">
                            <span>2</span>
                            <div className="c_step_des">
                            <p className="c_des_bold">Notify your medical professional that you are using VitalCells for newborn stem cell banking.</p>
                            </div>
                            </div>

                            <div className="c_steps">
                            <span>3</span>
                            <div className="c_step_des">
                            <p className="c_des_bold">Receive your collection kit </p>
                            <p>Your kit will come with everything needed for your medical provider to perform a successful collection of cord blood & cord tissue and a maternal blood draw on the day of delivery.</p>
                            <ul>
                            <li>You can open the collection kit to ensure everything is there but DO NOT OPEN OR BREAK ANY SEALS AS THESE STERILE MATERIALS SHOULD ONLY BE HANDLED BY MEDICAL PROFESSIONALS. </li>
                            <li>The maternal blood draw will be done at the time of delivery and will be packaged with the collection kit. If not, a waiver must be completed prior to processing.</li>
                            <li>If you do not receive your kit within 5-7 days, please contact VitalCells directly. </li>
                            </ul>




                            </div>
                            </div>
                            <div className="c_steps">
                            <span>4</span>
                            <div className="c_step_des">
                            <p className="c_des_bold">Call the medical courier to pick up the collection kit on delivery day.</p>
                            <ul>
                            <li>
                            Please use the telephone number provided in your collection kit to contact the pre-paid 24-hour medical courier service. The courier will retrieve and ship the collection kit, with <b>your baby’s cord blood, cord tissue and your maternal blood draw</b>, to the ACT facility for processing. No need to worry about payment as this courier service is included in the collection process. 
                            </li>
                            <li>Further details on the healthcare provider collection instructions, parents’ guide and kit instructions, shipping details and other information are provided inside the collection kit. 
                            </li>
                            </ul>
                            </div>
                            </div>
                            <div className="c_steps">
                            <span>5</span>
                            <div className="c_step_des">
                            <p className="c_des_bold">Leave it to VitalCells.  </p>
                            <ul>
                            <li>
                            Upon receiving the cord blood and cord tissues, VitalCells will send you updates, a banking certificate and pictures of your cells along the way. 
                            </li>
                            <li>
                            Your child's stem cells will then graduate to our adult bank, American Cell Technology. You will then be provided portal access to manage your child's stem cell storage. 
                            </li>
                            </ul>
                            </div>
                            </div>
                            </div>
                        </div>
                        }
                    </div>
                            </div>
                    </div>
                    </div>  
                    </div> 
                    </div>
                    </div>
                    </div> 
                            
                </MainLayout>
        );
    }
}

Pay.propTypes = {
    errors: PropTypes.object.isRequired,
    error: PropTypes.object.isRequired,
    connectSalesforce:PropTypes.func.isRequired,
    addPayment:PropTypes.func.isRequired,
    checkPromoCode:PropTypes.func.isRequired,
    sendMail:PropTypes.func.isRequired,
    
};

const mapStateToProps = (state) => ({
    page_name : state.pay,
    errors: state.errors,
  });
  
  export default connect(mapStateToProps, {connectSalesforce,addPayment,checkPromoCode, sendMail
  })(Pay);

// export default Pay;